<template>
  <div>
    <section class="section position-relative">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <accountMenu />
          </div>
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12">
            <div class="d-flex align-items-center justify-content-between">
              <h3 class="title">การผูกบัญชีรายได้</h3>
            </div>

            <p class="border-bottom mt-2"></p>

            <div>
              กรุณาระบุ Omise API Keys เพื่อกำหนดให้รายได้เข้าสู่บัญชี Omise
              จากการขายคอร์สของท่าน

              <p>
                สมัครสมาชิกกับ
                <a
                  href="https://www.omise.co/th/thailand"
                  target="_blank"
                  rel="noopener noreferrer"
                  >www.omise.co.th</a
                >
                (
                <a
                  href="/omise-keys-guide.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  >อ่านข้อมูลเพิ่มเติม</a
                >
                )
              </p>
            </div>

            <template v-if="config != null">
              <v-row>
                <v-col cols="12" md="6" lg="6">
                  <template v-if="isSecurityCompleted">
                    <div class="font-weight-bold mb-2">Omise API Keys</div>
                    <div class="">
                      <b-form-group label="Public Key">
                        <b-form-input
                          v-model="publicKey"
                          trim
                          placeholder=""
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group label="Secret Key">
                        <b-form-input
                          v-model="secretKey"
                          trim
                          placeholder=""
                        ></b-form-input>
                      </b-form-group>

                      <b-alert show variant="light" class="pa-3">
                        <div class="text-warning">
                          <span class="mdi mdi-alert"></span>
                          กรุณาตรวจสอบให้แน่ใจก่อนว่า API Keys
                          อยู่ในโหมดใช้งานจริง
                        </div>
                      </b-alert>

                      <b-button
                        variant="primary"
                        @click="$refs.verify3FADialog.open()"
                        :disabled="
                          !isSavable ||
                          (!isPublicKeyChange && !isSecretKeyChange)
                        "
                        >บันทึก</b-button
                      >
                    </div>
                  </template>
                  <template v-else>
                    <div>
                      <b-alert show variant="light" class="p-3">
                        <div class="font-weight-bold text-danger">
                          <i class="uil uil-shield-slash"></i>
                          ยังไม่ปลอดภัยเพียงพอ
                        </div>
                        <hr />
                        <p>
                          การตั้งค่าความปลอดภัยยังไม่สำเร็จเรียบร้อย
                          กรุณาตั้งค่าให้ครบทั้ง 3 รูปแบบ
                        </p>
                        <div>
                          <b-button
                            variant="outline-primary"
                            to="/account/security"
                            >ดำเนินการ</b-button
                          >
                        </div>
                      </b-alert>
                    </div>
                  </template>
                </v-col>
              </v-row>
            </template>
          </div>
        </div>
      </div>

      <template v-if="config != null">
        <verify-dialog
          ref="verify3FADialog"
          :config="config"
          @onShow="onVerifyDialogShow"
          @dismiss="onVerifyDismiss"
        ></verify-dialog>
      </template>

      <b-overlay :show="overlay" no-wrap></b-overlay>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import accountMenu from "../menu.vue";
import verifyDialog from "../../../components/verifyDialog.vue";
import InputSeries from "../../../components/input-series.vue";
import accountService, {
  twoFactorAuthenOptions,
  updateIncomeConfig,
  getIncomeConfig,
} from "../../../services/accountService";
import { EventBus } from "../../../event-bus";
export default {
  data: () => ({
    show2FAAuth: false,
    overlay: false,
    initialized: false,
    config: null,
    enabled2FA: null,
    twoFAoptions: twoFactorAuthenOptions,
    publicKey: null,
    secretKey: null,
    incomeConfig: null,
  }),
  components: {
    accountMenu,
    InputSeries,
    verifyDialog,
  },
  computed: {
    isSecurityCompleted() {
      if (this.config != null) {
        if (this.config.mail.verified == false) {
          return false;
        }
        if (this.config.authenticator.verified == false) {
          return false;
        }
        if (this.config.mobileOtp.verified == false) {
          return false;
        }
      } else {
        return false;
      }
      return true;
    },
    isPublicKeyChange() {
      if (
        this.incomeConfig != null &&
        this.incomeConfig.publicKey != this.publicKey
      ) {
        return true;
      }
      return false;
    },
    isSecretKeyChange() {
      if (
        this.incomeConfig != null &&
        this.incomeConfig.secretKey != this.secretKey
      ) {
        return true;
      }
      return false;
    },
    isSavable() {
      if (
        this.publicKey != "" &&
        this.publicKey != null &&
        this.secretKey != "" &&
        this.secretKey != null
      ) {
        return true;
      }
      return false;
    },
    enable2FA() {
      if (this.config.tfa != null && this.config.tfa != "") {
        return true;
      }

      return false;
    },
    isMailVerified() {
      if (this.config.mail != null) {
        return this.config.mail.verified;
      }

      return false;
    },
    isAuthenticatorVerified() {
      if (this.config.authenticator != null) {
        return this.config.authenticator.verified;
      }
      return false;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.overlay = true;
      accountService
        .getSecurityConfig()
        .then((cfg) => {
          this.config = cfg;

          this.getIncomeConfig();
        })
        .catch((err) => {
          // alert error
        })
        .finally(() => {
          this.overlay = false;
          this.initialized = true;
        });
    },
    getIncomeConfig() {
      this.overlay = true;
      getIncomeConfig()
        .then((result) => {
          this.incomeConfig = result;
          this.publicKey = result.publicKey;
          this.secretKey = result.secretKey;
        })
        .catch((err) => {
          // show error
          this.$bvModal.msgBoxOk("ไม่สามารถดำเนินการได้", {
            title: "เกิดข้อผิดพลาด",
            centered: true,
          });
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onVerifyDialogShow(val) {
      if (!val) {
        // close
        this.publicKey = this.incomeConfig.publicKey;
        this.secretKey = this.incomeConfig.secretKey;
      }
    },
    onVerifyDismiss(val) {
      if (val) {
        this.overlay = true;
        updateIncomeConfig({
          publicKey: this.publicKey,
          secretKey: this.secretKey,
        })
          .then((resp) => {
            this.$bvModal
              .msgBoxOk("บันทึกข้อมูลสำหรับรายได้สำเร็จแล้ว", {
                title: "ดำเนินการสำเร็จ",
                centered: true,
              })
              .then(() => {
                this.publicKey = null;
                this.secretKey = null;

                this.getIncomeConfig();
              });
          })
          .catch((err) => {
            // show error
            this.$bvModal.msgBoxOk("ไม่สามารถดำเนินการได้", {
              title: "เกิดข้อผิดพลาด",
              centered: true,
            });
          })
          .finally(() => {
            this.overlay = false;
          });
      } else {
        this.getIncomeConfig();
      }
    },
  },
};
</script>