<template>
  <b-modal
    :title="title"
    centered
    v-model="dialog"
    hide-header-close
    ok-title="ยกเลิก"
    @ok="dismiss"
    ok-variant="light"
    no-close-on-backdrop
    ok-only
    scrollable
  >
    <div class="bg-white text-center">
      <div class="font-weight-bold">
        E-Mail: <span class="font-weight-bold">{{ config.mail.email }}</span>
      </div>
      <div class="mb-3">
        รหัสอ้างอิง:
        <strong class="text-info font-weight-bold">{{ refNoEmail }}</strong>
      </div>
      <input-series
        :auto-focus="true"
        ref="inputEmailToken"
        width="360px"
        series-id="inputEmailToken"
        @filled="onEmailTokenFilled"
      ></input-series>
      <div class="mt-4 d-flex align-items-center">
        <div class="flex-grow-1">
          <template v-if="isCountdownEmail">
            <vue-countdown
              ref="countdownEmail"
              :time="timeValueEmail"
              :auto-start="false"
              @end="resetCountdownEmail"
              @abort="resetCountdownEmail"
              v-slot="{ totalSeconds }"
            >
              <div>
                เหลือเวลา
                <strong>
                  {{
                    totalSeconds == 0 ? timeValueEmail / 1000 : totalSeconds
                  }}</strong
                >
                วินาที
              </div>
              <div class="mt-2">
                <b-progress
                  :variant="progressAutoColor(totalSeconds)"
                  :value="
                    totalSeconds == 0 ? timeValueEmail / 1000 : totalSeconds
                  "
                  :max="timeValueEmail / 1000"
                ></b-progress>
              </div>
            </vue-countdown>
          </template>
          <template v-else-if="!isCountdownEmail && tokensState.email.verified">
            <div class="font-weight-bold text-success">
              <span class="mdi mdi-checkbox-marked-outline"></span>
              ยืนยันเรียบร้อยแล้ว
            </div>
          </template>
        </div>
        <div class="mt-0 ml-4">
          <b-button
            @click="reqEmailToken"
            variant="primary"
            :disabled="
              (isCountdownEmail && timeValueEmail > 0) ||
              tokensState.email.verified
            "
            >ขอรหัส OTP</b-button
          >
        </div>
      </div>
    </div>
    <hr />

    <div class="bg-white text-center">
      <div class="font-weight-bold">
        SMS: หมายเลขโทรศัพท์ลงท้าย 4 หลักด้วย
        <span class="font-weight-bold">{{ config.mobileOtp.last_digits }}</span>
      </div>
      <div class="mb-3">
        รหัสอ้างอิง:
        <strong class="text-info font-weight-bold">{{ refNo }}</strong>
      </div>
      <input-series
        :auto-focus="false"
        ref="inputMobileSMSToken"
        width="360px"
        series-id="inputMobileSMSToken"
        @filled="onSMSTokenFilled"
      ></input-series>
      <div class="mt-4 d-flex align-items-center">
        <div class="flex-grow-1">
          <template v-if="isCountdown">
            <vue-countdown
              ref="countdown"
              :time="timeValue"
              :auto-start="false"
              @end="resetCountdown"
              @abort="resetCountdown"
              v-slot="{ totalSeconds }"
            >
              <div>
                เหลือเวลา
                <strong>
                  {{
                    totalSeconds == 0 ? timeValue / 1000 : totalSeconds
                  }}</strong
                >
                วินาที
              </div>
              <div class="mt-2">
                <b-progress
                  :variant="progressAutoColor(totalSeconds)"
                  :value="totalSeconds == 0 ? timeValue / 1000 : totalSeconds"
                  :max="timeValue / 1000"
                ></b-progress>
              </div>
            </vue-countdown>
          </template>
          <template v-else-if="!isCountdown && tokensState.mobile.verified">
            <div class="font-weight-bold text-success">
              <span class="mdi mdi-checkbox-marked-outline"></span>
              ยืนยันเรียบร้อยแล้ว
            </div>
          </template>
        </div>
        <div class="mt-0 ml-4">
          <b-button
            @click="reqOTP"
            variant="primary"
            :disabled="
              (isCountdown && timeValue > 0) ||
              !tokensState.email.verified ||
              tokensState.mobile.verified
            "
            >ขอรหัส OTP</b-button
          >
        </div>
      </div>
    </div>
    <hr />

    <div class="bg-white text-center">
      <h6 class="font-weight-bold">Authenticator App</h6>

      <div class="mt-2 mb-2">กรุณาป้อนรหัส 6 หลัก ที่ปรากฎในแอปฯ</div>
      <input-series
        :auto-focus="false"
        ref="inputAuthenticatorToken"
        width="360px"
        series-id="inputAuthenticatorToken"
        @filled="onAuthenticatorTokenFilled"
      ></input-series>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="d-flex" style="width: 100%">
        <div class="flex-grow-1">
          <b-button variant="light" @click="cancel()" :disabled="overlay">
            ยกเลิก
          </b-button>
        </div>
        <div class="flex-shrink-1">
          <b-button
            @click="checkingFactorsComplete"
            variant="primary"
            :disabled="!allFactorsVerified || overlay"
            >ดำเนินการต่อ</b-button
          >
        </div>
      </div>
    </template>

    <b-overlay :show="overlay" no-wrap></b-overlay>
  </b-modal>
</template>

<script>
import InputSeries from "../components/input-series.vue";
import accountService, { threeFactorsState } from "../services/accountService";
import VueCountdown from "@chenfengyuan/vue-countdown";
export default {
  props: {
    title: {
      type: String,
      default: "การยืนยันด้วย OTP",
    },
    config: {
      type: Object,
      required: true,
    },
  },
  components: {
    InputSeries,
    VueCountdown,
  },
  data: () => ({
    dialog: false,
    overlay: false,
    mobileNo: null,
    email: null,
    isCountdown: true,
    isCountdownEmail: true,
    refNo: "",
    refNoEmail: "",
    expiresMinute: 1,
    expiresMinuteEmail: 3,
    timeValue: 0,
    timeValueEmail: 0,
    tokensState: new threeFactorsState(),
  }),
  watch: {
    dialog(val) {
      this.$emit("onShow", val);
    },
  },
  computed: {
    allFactorsVerified() {
      if (this.tokensState == null) {
        return false;
      }

      if (!this.tokensState.email.verified) {
        return false;
      }

      if (!this.tokensState.mobile.verified) {
        return false;
      }

      if (!this.tokensState.authenticator.verified) {
        return false;
      }
      return true;
    },
  },
  methods: {
    open() {
      this.dialog = true;

      // this.reqEmailToken();
    },
    dismiss(val = false) {
      this.refNo = null;
      this.refNoEmail = null;
      this.tokensState = null; // clear memory
      this.tokensState = new threeFactorsState(); // set new default
      this.dialog = false;
      this.$emit("dismiss", val);
    },
    close() {
      this.dismiss();
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          setTimeout(() => {
            resolve();
          });
        });
      });
    },
    //#region E-mail
    reqEmailToken() {
      this.overlay = true;
      this.resetCountdownEmail();
      this.refNoEmail = "";
      accountService.email
        .reqTOTP()
        .then((result) => {
          // console.log(result);
          // this.timeValue = (60 + result.left) * 1000; // in case plus remaining time
          this.timeValueEmail = this.expiresMinuteEmail * 60 * 1000;
          this.refNoEmail = result.refNo;
          // console.log(this.timeValue, this.timeValue / 1000);
          this.isCountdownEmail = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.countdownEmail.start();
            });
          });
          this.$refs.inputEmailToken.focusFirst();
        })
        .catch((err) => {
          // do nothing
          this.isCountdownEmail = false;
          this.refNo = "";
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onEmailTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.email
        .verifyTOTP(token, false)
        .then((result) => {
          if (result == true) {
            // save state
            this.tokensState.email.refNo = this.refNoEmail;
            this.tokensState.email.token = token;
            this.tokensState.email.verified = true;

            this.$refs.inputEmailToken.success();
            this.$refs.countdownEmail.end();
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          this.$refs.inputEmailToken.failed();

          this.tokensState.email.refNo = null;
          this.tokensState.email.token = null;
          this.tokensState.email.verified = false;
          //   console.log(err);
          this.$refs.inputEmailToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    resetCountdownEmail() {
      this.isCountdownEmail = false;
      this.timeValueEmail = 0;
    },
    //#endregion E-Mail
    //#region SMS
    reqOTP() {
      this.overlay = true;
      this.resetCountdown();
      this.refNo = "";
      accountService.mobile
        .reqTOTP("", true)
        .then((result) => {
          // console.log(result);
          // this.timeValue = (60 + result.left) * 1000; // in case plus remaining time
          this.timeValue = this.expiresMinute * 60 * 1000;
          this.refNo = result.refNo;
          // console.log(this.timeValue, this.timeValue / 1000);
          this.isCountdown = true;
          this.$nextTick(() => {
            setTimeout(() => {
              this.$refs.countdown.start();
            });
          });
          this.$refs.inputMobileSMSToken.focusFirst();
        })
        .catch((err) => {
          // do nothing
          this.isCountdown = false;
          this.refNo = "";
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    onSMSTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.mobile
        .verifyTOTP(token, false)
        .then((result) => {
          if (result == true) {
            this.tokensState.mobile.refNo = this.refNo;
            this.tokensState.mobile.token = token;
            this.tokensState.mobile.verified = true;

            this.$refs.inputMobileSMSToken.success();
            this.$refs.countdown.end();
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          this.$refs.inputMobileSMSToken.failed();

          this.tokensState.mobile.refNo = null;
          this.tokensState.mobile.token = null;
          this.tokensState.mobile.verified = false;
          // console.log(err);
          this.$refs.inputMobileSMSToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    progressAutoColor(val) {
      if (val > 40) {
        return "success";
      } else if (val > 20) {
        return "warning";
      } else {
        return "danger";
      }
    },
    onMobileChange(val) {
      this.mobileNo = val;
      this.refNo = "";
    },
    resetCountdown() {
      this.isCountdown = false;
      this.timeValue = 0;
    },
    //#endregion SMS
    onAuthenticatorTokenFilled(token) {
      // validate token if success then save
      this.overlay = true;
      accountService.authenticatorService
        .verify(token, "check")
        .then((result) => {
          if (result == true) {
            this.tokensState.authenticator.token = token;
            this.tokensState.authenticator.verified = true;

            this.$refs.inputAuthenticatorToken.success();

            this.checkingFactorsComplete();
          } else {
            throw new Error("invalid token");
          }
        })
        .catch((err) => {
          this.$refs.inputAuthenticatorToken.failed();

          this.tokensState.authenticator.token = null;
          this.tokensState.authenticator.verified = false;
          //   console.log(err);
          this.$refs.inputAuthenticatorToken.clear();
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    checkingFactorsComplete() {
      if (!this.tokensState.email.verified) {
        this.$bvModal.msgBoxOk("กรุณายืนยัน OTP ด้วยอีเมลให้ถูกต้อง", {
          title: "แจ้งเตือน",
          centered: true,
        });
        return;
      }

      if (!this.tokensState.mobile.verified) {
        this.$bvModal.msgBoxOk("กรุณายืนยัน OTP ด้วย Mobile SMS ให้ถูกต้อง", {
          title: "แจ้งเตือน",
          centered: true,
        });
        return;
      }

      if (!this.tokensState.authenticator.verified) {
        this.$bvModal.msgBoxOk(
          "กรุณายืนยัน OTP ด้วย Authenticator App ให้ถูกต้อง",
          {
            title: "แจ้งเตือน",
            centered: true,
          }
        );
        return;
      }

      // success case
      this.dismiss(true);
    },
  },
};
</script>