var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":_vm.title,"centered":"","hide-header-close":"","ok-title":"ยกเลิก","ok-variant":"light","no-close-on-backdrop":"","ok-only":"","scrollable":""},on:{"ok":_vm.dismiss},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"flex-grow-1"},[_c('b-button',{attrs:{"variant":"light","disabled":_vm.overlay},on:{"click":function($event){return cancel()}}},[_vm._v(" ยกเลิก ")])],1),_c('div',{staticClass:"flex-shrink-1"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.allFactorsVerified || _vm.overlay},on:{"click":_vm.checkingFactorsComplete}},[_vm._v("ดำเนินการต่อ")])],1)])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"bg-white text-center"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" E-Mail: "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.config.mail.email))])]),_c('div',{staticClass:"mb-3"},[_vm._v(" รหัสอ้างอิง: "),_c('strong',{staticClass:"text-info font-weight-bold"},[_vm._v(_vm._s(_vm.refNoEmail))])]),_c('input-series',{ref:"inputEmailToken",attrs:{"auto-focus":true,"width":"360px","series-id":"inputEmailToken"},on:{"filled":_vm.onEmailTokenFilled}}),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.isCountdownEmail)?[_c('vue-countdown',{ref:"countdownEmail",attrs:{"time":_vm.timeValueEmail,"auto-start":false},on:{"end":_vm.resetCountdownEmail,"abort":_vm.resetCountdownEmail},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var totalSeconds = ref.totalSeconds;
return [_c('div',[_vm._v(" เหลือเวลา "),_c('strong',[_vm._v(" "+_vm._s(totalSeconds == 0 ? _vm.timeValueEmail / 1000 : totalSeconds))]),_vm._v(" วินาที ")]),_c('div',{staticClass:"mt-2"},[_c('b-progress',{attrs:{"variant":_vm.progressAutoColor(totalSeconds),"value":totalSeconds == 0 ? _vm.timeValueEmail / 1000 : totalSeconds,"max":_vm.timeValueEmail / 1000}})],1)]}}],null,false,77748392)})]:(!_vm.isCountdownEmail && _vm.tokensState.email.verified)?[_c('div',{staticClass:"font-weight-bold text-success"},[_c('span',{staticClass:"mdi mdi-checkbox-marked-outline"}),_vm._v(" ยืนยันเรียบร้อยแล้ว ")])]:_vm._e()],2),_c('div',{staticClass:"mt-0 ml-4"},[_c('b-button',{attrs:{"variant":"primary","disabled":(_vm.isCountdownEmail && _vm.timeValueEmail > 0) ||
            _vm.tokensState.email.verified},on:{"click":_vm.reqEmailToken}},[_vm._v("ขอรหัส OTP")])],1)])],1),_c('hr'),_c('div',{staticClass:"bg-white text-center"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" SMS: หมายเลขโทรศัพท์ลงท้าย 4 หลักด้วย "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.config.mobileOtp.last_digits))])]),_c('div',{staticClass:"mb-3"},[_vm._v(" รหัสอ้างอิง: "),_c('strong',{staticClass:"text-info font-weight-bold"},[_vm._v(_vm._s(_vm.refNo))])]),_c('input-series',{ref:"inputMobileSMSToken",attrs:{"auto-focus":false,"width":"360px","series-id":"inputMobileSMSToken"},on:{"filled":_vm.onSMSTokenFilled}}),_c('div',{staticClass:"mt-4 d-flex align-items-center"},[_c('div',{staticClass:"flex-grow-1"},[(_vm.isCountdown)?[_c('vue-countdown',{ref:"countdown",attrs:{"time":_vm.timeValue,"auto-start":false},on:{"end":_vm.resetCountdown,"abort":_vm.resetCountdown},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var totalSeconds = ref.totalSeconds;
return [_c('div',[_vm._v(" เหลือเวลา "),_c('strong',[_vm._v(" "+_vm._s(totalSeconds == 0 ? _vm.timeValue / 1000 : totalSeconds))]),_vm._v(" วินาที ")]),_c('div',{staticClass:"mt-2"},[_c('b-progress',{attrs:{"variant":_vm.progressAutoColor(totalSeconds),"value":totalSeconds == 0 ? _vm.timeValue / 1000 : totalSeconds,"max":_vm.timeValue / 1000}})],1)]}}],null,false,401754852)})]:(!_vm.isCountdown && _vm.tokensState.mobile.verified)?[_c('div',{staticClass:"font-weight-bold text-success"},[_c('span',{staticClass:"mdi mdi-checkbox-marked-outline"}),_vm._v(" ยืนยันเรียบร้อยแล้ว ")])]:_vm._e()],2),_c('div',{staticClass:"mt-0 ml-4"},[_c('b-button',{attrs:{"variant":"primary","disabled":(_vm.isCountdown && _vm.timeValue > 0) ||
            !_vm.tokensState.email.verified ||
            _vm.tokensState.mobile.verified},on:{"click":_vm.reqOTP}},[_vm._v("ขอรหัส OTP")])],1)])],1),_c('hr'),_c('div',{staticClass:"bg-white text-center"},[_c('h6',{staticClass:"font-weight-bold"},[_vm._v("Authenticator App")]),_c('div',{staticClass:"mt-2 mb-2"},[_vm._v("กรุณาป้อนรหัส 6 หลัก ที่ปรากฎในแอปฯ")]),_c('input-series',{ref:"inputAuthenticatorToken",attrs:{"auto-focus":false,"width":"360px","series-id":"inputAuthenticatorToken"},on:{"filled":_vm.onAuthenticatorTokenFilled}})],1),_c('b-overlay',{attrs:{"show":_vm.overlay,"no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }